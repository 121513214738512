/* global $ */
import {HOST} from './_global.js';

export function setTopMainVisual(data) {

  // variables
  const
    NUMBER_OF_ANCHOR = 5,
    ACTOR_SIZE = 130,
    STAGE_X_MARGIN = 70,
    STAGE_Y_MARGIN = 86/*,
    AGE_MIN = 3000,
    AGE_RANDOM = 3000*/;
  let
    stanzas = data['stanza:stanzas'],
    availableAnchors = [],
    stagingAnchors = [],
    $mainVisual = $('#MainVisual'),
    isReady = false,
    particleCounter = 0,
    $stage, $bg, stageSize, stageOrigin, actorSpace;

  var AnchorBall = function(stanza) {

    // private variables
    var _self = this,
        _id = stanza['@id'],
        //_label = stanza['stanza:label'],
        _img, _index, _left, $_elm;
    _id = _id.substring(_id.lastIndexOf('/') + 1);
    _img = new Image();
    _img.src = HOST + 'assets/img/thumbnail/' + _id + '.png';

    // private methods

    var ready = function() {
      availableAnchors.push(_self);
    };
    if (_img.complete) {
      ready();
    } else {
      _img.onload = function() { ready(); };
    }

    // public variables
    this.img = _img;
    this.id = _id;
    this.x = function() {
      return _left + ACTOR_SIZE * 0.5;
    };
    this.y = function() {
      return stageOrigin.y + ACTOR_SIZE * 0.5;
    };

    // public methods

    this.appearOnTheStage = function(index) {
      _index = index;
      $stage.append('<a class="actor" id="' + _id + '" href="stanza.html?' + _id + '"><img src="' + _img.src + '"></a>');
      _left = stageOrigin.x + index * (actorSpace + ACTOR_SIZE);
      $_elm = $('#' + _id);
      $_elm
        .css({
          top: stageOrigin.y,
          left: _left
        });
      // particle
      window.setTimeout(function() {
        for (var i = 0; i < 10; i++) {
          new Particle(_self);
        }
      }, 200);
      // die
      /*
      window.setTimeout(function(){
        $_elm.addClass('exit');
          window.setTimeout(function(){
          }, );


        for (var i in stagingAnchors) {
          console.log(stagingAnchors[i] === _self);
        }
      }, AGE_MIN + AGE_RANDOM * Math.random());
      */
    };

    // TODO: 退場

  };

  var Particle = function(target) {
    var _id = 'particle' + particleCounter++,
        $_elm,
        size = Math.floor(Math.random() * 50),
        color = ['#f39800', '#ffd400', '#ffd400', '#ffd400', '#edeada'][Math.floor(Math.random() * 5)],
        top = target.y() - size * 0.5,
        left = target.x() - size * 0.5,
        duration = 750 + 2000 * Math.random(),
        radian = 2 * Math.PI * Math.random(),
        distance = 50 + 150 * Math.random();
    $bg.append('<div class="particle" id="' + _id + '" style="width: ' + size + 'px; height: ' + size + 'px; background-color: ' + color + '; top: ' + top + 'px; left: ' + left + 'px;"></div>');
    $_elm = $('#' + _id);
    $_elm
      .animate({
        top: top + Math.sin(radian) * distance,
        left: left + Math.cos(radian) * distance,
        width: 0,
        height: 0
      }, {
        duration: duration,
        complete: function() {
          //$_elm.addClass('hidden');
          $_elm.remove();
        }
      });
  };

  // animation
  var animation = function() {

    if (!isReady) {
      // wait load
      if (availableAnchors.length > NUMBER_OF_ANCHOR) {
        isReady = true;
        choiceActors();
      }
    } else {
      // actor
      //for (var i in stagingAnchors) {
      //  //stagingAnchors[i].step();
      //}
      // background
    }
  };

  // choice actors (anchors)
  var choiceActors = function() {
    // choice & lead to waiting room
    var waitingRoom = [];
    for (var i = 0; i < NUMBER_OF_ANCHOR; i++) {
      var index = Math.floor(Math.random() * availableAnchors.length),
          actor = availableAnchors.splice(index, 1);
      waitingRoom.push(actor[0]);
    }
    // exit from stage 
    availableAnchors = availableAnchors.concat(stagingAnchors);
    stagingAnchors = waitingRoom;
    // on stage!
    for (i in stagingAnchors) {
      stagingAnchors[i].appearOnTheStage(i);
    }
  };

  // set up
  (function(){
    //screenRect
    // stage
    $mainVisual.append('<div id="stage-bg"></div><div id="stage"></div>');
    $stage = $('#stage');
    $bg = $('#stage-bg');
    stageSize = {
      width: $mainVisual.width(),
      height: $mainVisual.height()
    };
    actorSpace = (stageSize.width - STAGE_X_MARGIN * 2 - ACTOR_SIZE * NUMBER_OF_ANCHOR) / (NUMBER_OF_ANCHOR - 1);
    stageOrigin = {
      x: STAGE_X_MARGIN,
      y: STAGE_Y_MARGIN
    };
    // create anchor ball instance
    for (var i in stanzas) {
      new AnchorBall(stanzas[i]);
    }
    // start animation
    window.setInterval(function(){ animation(); }, 1000 / 30);

  })();

}
