export function setMetastanza() {
  //reset body style for metastanza showcase page
  document.querySelector('body').style.minWidth = 0;

  const openButton = document.getElementById('openButton');
  const closeButton = document.getElementById('closeButton');
  const styledrawerButton = document.getElementById('styledrawerButton');
  const styleDrawerInner = document.getElementById('styleDrawerInner');
  const sampleTab = document.getElementById('sampleTab');
  const customizeTab = document.getElementById('customizeTab');
  const linkBtn = document.getElementById('linkBtn');
  const importBtn = document.getElementById('importBtn');
  const sampleContent = document.getElementById('sampleContent');
  const customizeContent = document.getElementById('customizeContent');
  const listContainer = document.getElementsByClassName('list_container')
  const stanzaNames = ['Tree', `Barchart`, 'Piechart', 'Linechart', 'Scatterplot', 'Scorecard', 'Text', 'Pagination-table', 'Hash-table', 'Scroll-table']
  const COLORSCHEME_NUM = 6;
  const PATH_THEMES = "https://togostanza.github.io/togostanza-themes";

  //stylesheet name (display name)
  const stylesheetNames = ['Sunset', 'Ocean', 'Bright', 'Pale', 'Dark', 'CUD', 'Console', 'Paper'];

  //get foreground colors (stanza container background color) 
  const fgColors = [];
  stylesheetNames.forEach(
    (stylesheet, i) => {
      fetch(`${PATH_THEMES}/official/${stylesheet.toLowerCase()}.css`)
        .then(response => response.text())
        .then(data => {
          const fgRegex = new RegExp(`--togostanza-background-color: (#[0-9a-fA-F]{6}|[0-9a-fA-F]{3})`)
          let fgColor = data.match(fgRegex)[1]
          fgColors[i] = fgColor;
        })
    }
  )

  //add hyperlink to stanzas
  const stanzas = document.getElementsByClassName('stanza');
  const stanzaTitles = document.getElementsByClassName('list_title');
  for (let i = 0; i < stanzas.length; i++) {
    stanzaTitles[i].insertAdjacentHTML('afterbegin', `<a href="https://togostanza.github.io/metastanza/${stanzaNames[i].toLowerCase()}.html">${stanzaNames[i]}</a>`);
  }

  //open styleDrawer
  openButton.addEventListener('click', function () {
    styledrawerButton.classList.add('-close');
    styleDrawerInner.classList.remove('-close');
  })

  //close styleDrawer
  closeButton.addEventListener('click', () => {
    styleDrawerInner.classList.add('-close');
    styledrawerButton.classList.remove('-close');
  })

  //open/close sample/customize tab
  sampleTab.addEventListener('click', () => {
    customizeTab.classList.add('-close');
    sampleTab.classList.remove('-close');
    customizeContent.classList.add('-close');
    sampleContent.classList.remove('-close');
  })

  //open/close sample/customize tab
  customizeTab.addEventListener('click', () => {
    sampleTab.classList.add('-close');
    customizeTab.classList.remove('-close');
    sampleContent.classList.add('-close');
    customizeContent.classList.remove('-close');
  })

  //create stylesheet list
  const stylesheetList = document.getElementById('stylesheetList');
  for (let i = 0; i < stylesheetNames.length; i++) {
    stylesheetList.insertAdjacentHTML(
      'beforeend',
      `<li class="stylesheet">
        <input class="style_selector" id="styleRadioButton${i}" name="style_selector" type="radio" ${i === 0 ? "checked" : ""}>
        <label class="style_name" for="styleRadioButton${i}">${stylesheetNames[i]}</label>
        <label class="colorscheme_list" for="styleRadioButton${i}">
          <ul id="colorScheme${i}" class="color-scheme"> 
            ${'<li class="colorbox"></li>'.repeat(COLORSCHEME_NUM)}
          </ul>
        </label>
      </li>
      `
    );
  }

  //set foreground color depends on selected style (both sample tab and customize tab)
  const setFgColor = function (color) {
    for (const elem of listContainer) {
      elem.style.backgroundColor = color;
    }
  }
  //forEachも使える
  //listContainers

  //set background color (at customize tab)
  const contents = document.getElementById('contents');
  // const setBgColor = function (color) {
  //   for (let i = 0; i < listContainer.length; i++) {
  //     contents.style.backgroundColor = color;
  //   }
  // };
  const setBgColor = function (color) {
    for (const elem of listContainer) {
      contents.style.backgroundColor = color;
    }
  }

  // set color schemes
  stylesheetNames.forEach(
    (stylesheet, i) => {
      fetch(`../assets/css/metastanza/${stylesheet.toLowerCase()}.css`)
        .then(response => response.text())
        .then(data => {
          const colors = [];
          for (let j = 0; j < COLORSCHEME_NUM; j++) {
            const regex = new RegExp(`--togostanza-series-${j}-color: (#[0-9a-fA-F]{6}|[0-9a-fA-F]{3})`)
            colors[j] = data.match(regex)[1];
          }
          const colorScheme = document.getElementById(`colorScheme${i}`);
          for (let k = 0; k < colorScheme.childElementCount; k++) {
            colorScheme.children[k].setAttribute('style', `background-color : ${colors[k]}; border-color : ${fgColors[i] ? fgColors[i] : "#FFFFFF"};`);
          }
          setFgColor(fgColors[i]);
        })
    }
  )

  //get current applied stylesheet name
  function getCurrentStyleName() {
    return document.querySelector('#stylesheetList input[type="radio"]:checked + .style_name').textContent;
  };

  //set export tag
  const code = document.getElementById('code');
  const styleTag = document.createElement('p');
  const setTag = function () {
    if (linkBtn.checked) {
      styleTag.innerText = `<link rel="stylesheet" href="${PATH_THEMES}/official/${getCurrentStyleName().toLowerCase()}.css">`;
    } else if (importBtn.checked) {
      styleTag.innerText = `@import url("${PATH_THEMES}/official/${getCurrentStyleName().toLowerCase()}.css")`;
    }
    code.appendChild(styleTag);
  }

  //change export tag
  linkBtn.addEventListener('click', () => {
    linkBtn.checked = 'true';
    importBtn.checked = '';
    styleTag.innerText = styleTag.innerText.replace('@import url(', '<link rel=');
    styleTag.innerText = styleTag.innerText.replace(')', '>');
  })
  importBtn.addEventListener('click', () => {
    importBtn.checked = 'true';
    linkBtn.checked = '';
    styleTag.innerText = styleTag.innerText.replace('<link rel=', '@import url(');
    styleTag.innerText = styleTag.innerText.replace('>', ')');
  })

  //set download file
  const dlBtn = document.getElementById('dlBtn');
  function setDownloadFile() {
    fetch(`${PATH_THEMES}/official/${getCurrentStyleName().toLowerCase()}.css`)
      .then(response => response.text())
      .then(data => {
        const downloadedFile = data;
        const blob = new Blob([downloadedFile], { type: 'text/css' });
        const objectURL = URL.createObjectURL(blob);
        dlBtn.setAttribute('href', objectURL);
        dlBtn.setAttribute('download', getCurrentStyleName());
      })
  }

  //set initial condition
  const stylesheetLink = document.createElement('link');
  const init = () => {
    stylesheetLink.setAttribute('rel', 'stylesheet');
    document.body.appendChild(stylesheetLink);
    stylesheetLink.setAttribute('href', `${PATH_THEMES}/official/${stylesheetNames[0].toLowerCase()}.css`);
    setFgColor(fgColors[0]);
    setTag();
    setDownloadFile();
  }
  init();

  //select a stylesheet to apply
  const styleSelectorButtons = document.getElementsByClassName('style_selector');
  const vegaStanzas = document.querySelectorAll('.vegastanza');
  const changeStylesheet = () => {
    for (let i = 0; i < styleSelectorButtons.length; i++) {
      styleSelectorButtons[i].addEventListener('click', () => {
        stylesheetLink.setAttribute('href', `${PATH_THEMES}/official/${stylesheetNames[i].toLowerCase()}.css`);
        for (let j = 0; j < vegaStanzas.length; j++) {
          vegaStanzas[j].render();
        }
        setFgColor(fgColors[i]);
        setTag();
        setDownloadFile();
      })
    }
  }
  changeStylesheet();

  //create uploaded colorscheme
  const uploadedColorInfo = document.getElementById('uploadedColorInfo');
  const createColorScheme = function (element) {
    element.innerHTML =
      ` <hr>
          <span class="label">Color scheme</span>
          <form id="uploadedColorScheme" class="color-scheme">
          ${'<input class="colorbox uploded_colorbox" type="color">'.repeat(COLORSCHEME_NUM)}
          </form>
          <span class="label">Stanza background color</span>
          <div class="color_form">
            <input id="fgColorPicker" class="colorbox colorpicker" type="color">
            <input id="fgColorInput" class="colorinput" type="text">
          </div>
          <hr>
        `;
  }

  // get uploded stylesheet (from input element)
  const uploadStylesheet = function () {
    const uploadedStylesheet = document.getElementById('uploadedStylesheet');
    uploadedStylesheet.addEventListener('change', (e) => {
      const file_reader = new FileReader();
      file_reader.addEventListener('load', function (e) {
        const uploadedFile = e.target.result;
        const blob = new Blob([uploadedFile], { type: 'text/css' });
        const objectURL = URL.createObjectURL(blob);
        stylesheetLink.setAttribute('href', objectURL);

        createColorScheme(uploadedColorInfo);

        const colors = [];
        for (let j = 0; j < COLORSCHEME_NUM; j++) {
          const regex = new RegExp(`--togostanza-series-${j}-color: (#[0-9a-fA-F]{6}|[0-9a-fA-F]{3})`)
          colors[j] = uploadedFile.match(regex)[1]
        }

        //set color change event to uploaded colorscheme input
        const uploadedColorBoxes = document.getElementsByClassName('uploded_colorbox')
        for (let i = 0; i < uploadedColorBoxes.length; i++) {
          uploadedColorBoxes[i].addEventListener('change', () => {
            for (let j = 0; j < stanzas.length; j++) {
              stanzas[j].style.setProperty(`--togostanza-series-${i}-color`, uploadedColorBoxes[i].value);
            }
          })
        }

        //set background color
        const uploadedColorScheme = document.getElementById('uploadedColorScheme');
        for (let k = 0; k < COLORSCHEME_NUM; k++) {
          uploadedColorScheme.children[k].value = colors[k];
        }

        //set foreground color
        const fgColorPicker = document.getElementById('fgColorPicker');
        const fgColorInput = document.getElementById('fgColorInput');
        const fgRegex = new RegExp(`--togostanza-background-color: (#[0-9a-fA-F]{6}|[0-9a-fA-F]{3})`)
        const fgColor = uploadedFile.match(fgRegex)[1]
        setFgColor(fgColor);
        setBorderColor(uploadedColorScheme.children, fgColor);
        fgColorPicker.value = fgColor;
        fgColorInput.value = fgColor;
        for (let i = 0; i < stanzas.length; i++) {
          stanzas[i].style.setProperty(`--togostanza-background-color`, fgColor);
        }

        //set foreground color and its change event (at customize tab)
        fgColorPicker.addEventListener('change', () => {
          setFgColor(fgColorPicker.value);
          setBorderColor(uploadedColorScheme.children, fgColorPicker.value);
          fgColorInput.value = fgColorPicker.value;
          for (let i = 0; i < stanzas.length; i++) {
            stanzas[i].style.setProperty(`--togostanza-background-color`, fgColorPicker.value);
          }
        })
        fgColorInput.addEventListener('keypress', () => {
          setFgColor(fgColorPicker.value);
          setBorderColor(uploadedColorScheme.children, fgColorPicker.value);
          fgColorPicker.value = fgColorInput.value;
          for (let i = 0; i < stanzas.length; i++) {
            stanzas[i].style.setProperty(`--togostanza-background-color`, fgColorInput.value);
          }
        })
      });
      file_reader.readAsText(e.target.files[0]);

      const uploadedStyleName = document.getElementById('uploadedStyleName');
      uploadedStyleName.setAttribute('class', 'style_name');
      uploadedStyleName.innerText = e.target.files[0].name;
    });
  }
  uploadStylesheet();

  //set foreground color depends on selected style (only customize tab)
  const setBorderColor = function (elements, color) {
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.borderColor = color;
    }
  }

  //change background color (at customize tab)
  const bgColorPicker = document.getElementById('bgColorPicker');
  const bgColorInput = document.getElementById('bgColorInput');
  bgColorPicker.addEventListener('change', () => {
    setBgColor(bgColorPicker.value);
    bgColorInput.value = bgColorPicker.value
  })
  bgColorInput.addEventListener('keypress', () => {
    setBgColor(bgColorInput.value);
    bgColorPicker.value = bgColorInput.value
  })
}