/* global $ */
import {HOST, stanzaCounter, trimStanzaId, makeTags, makeIframeHtml, makeJSStanza} from './_global.js';

let jsStanzas;

export function setStanza(data) {
  
  const urlVars = {};
  window.location.search.substring(1).split('&').forEach(keyvalue => {
    const keyvalue2 = keyvalue.split('=');
    urlVars[keyvalue2[0]] = keyvalue2[1];
  })
  if (urlVars.id === undefined) {
    return;
  }
  var stanzaId = urlVars.id,
      stanzas = data['stanza:stanzas'],
      $iframe,
      currentSstanza, stanza, stanzaHtml, i;

  for(i in stanzas){
    stanza = stanzas[i];
    if (stanzaId === trimStanzaId(stanza['@id'])) {
      currentSstanza = stanza;
    }
  }

  makeLinkImport(currentSstanza);

  if (currentSstanza) {
    // make stanza
    switch (currentSstanza['stanza:implemented']) {
      case 'ruby': stanzaHtml = makeIframeHtml(currentSstanza); break;
      case 'javascript':
        stanzaHtml = makeJSStanza(currentSstanza);
        jsStanzas = {
          data: currentSstanza,
          html: stanzaHtml
        };
        stanzaHtml = 'loading...'
        break;
    }
    var html = `
    <h1 class="pagetitle">${currentSstanza['stanza:label']}</h1>
    ${makeTags(currentSstanza)}
    <div class="lead">${currentSstanza['stanza:definition']}</div>
    <div class="showcase-detail">
      <ul class="showcase_id">
        ${currentSstanza['stanza:parameter'].map(param => `
          <li ${param['stanza:required'] ? ' class="required"' : ''}>
            <dl>
              <dt>${param['stanza:key']}</dt><dd><input class="id_box" type="text" value="${param['stanza:example']}" data-correspound="${param['stanza:key']}"><p class="eg">${param['stanza:description']}</p></dd>
            </dl>
          </li>
        `).join('')}
      </ul>
      <div class="showcase_code"><code></code></div>
      <div class="showcase-display">${stanzaHtml}</div>
      <p class="explain">The above <span class="html_tag">&lt;div&gt;</span> will automatically embed the following Stanza in your HTML page.</p>
    </div>
    `;
    $('#Contents').append(html);
    $('.showcase_code code').text(currentSstanza['stanza:usage']);
    $iframe = $('iframe#stanza-frame-' + stanzaCounter);
    $iframe.on('load', function(){
      $iframe.removeClass('unload');
    });

    var changeParameter = function() {
      var src = $iframe.attr('src'),
          leftSrc = src.substring(0, src.indexOf('?') + 1);
      $iframe
        .addClass('unload')
        .attr('src', leftSrc + (function(){
          var params = [];
          $('.id_box').each(function(){
            params.push(this.dataset.correspound + '=' + this.value);
          });
          return params.join('&');
        })());
    };

    // input
    $('input.id_box').on({
      focus: function(){
        this.lastValue = this.value;
      },
      blur: function() {
        if (this.value !== this.lastValue) {
          changeParameter();
        }
      },
      keyup: function(e) {
        if (e.which === 13) {
          changeParameter();
        }
      }
    });
  } else {
    window.alert('invalid parameter');
  }

  // make stanza list
  var html2 = '<ul>';
  for(i in stanzas){
    stanza = stanzas[i];
    html2 += '<li' + (stanza['@id'] === currentSstanza['@id'] ? ' class="current"' : '') + '><a href="http://' + HOST + '?' + trimStanzaId(stanza['@id']) + '">' + stanza['stanza:label'] + '</a></li>';
  }
  html2 += '</ul>';
  $('.showcase-index_inner').append(html2);

}

function makeLinkImport(stanza) {
  const head = document.querySelector('head');
  if (stanza['stanza:implemented'] === 'javascript') {
    const link = document.createElement('link');
    link.rel = 'import';
    link.href = stanza['stanza:host'] + stanza['@id'];
    link.setAttribute('stanzaId', stanza['@id']);
    link.addEventListener('load', (e) => {
      $('.showcase-display').html(jsStanzas.html)
    })
    head.appendChild(link);
  }
}
