export const HOST = document.querySelector('html').dataset.pageType === 'index' ? './' : '../';
//export const HOST = 'http://penqe.com/togostanza/';

export const STANZA_ID_PREFIX = 'stanza-frame-';

export let stanzaCounter = 0;

export const TS = {
  STANZA_PUBLIC_PARAMS: {
    TYPE: "stanza:type",
    DISPLAY: "stanza:display",
    CONTEXT: "stanza:context",
    PROVIDER: "stanza:provider",
    LICENSE: "stanza:license",
    IMPLEMENTED: "stanza:implemented"
  }
};

export function trimStanzaId(stanzaId) {
  return stanzaId.substring(stanzaId.lastIndexOf('/') + 1);
}

export function makeTags(stanza) {
  return `
  <ul class="showcase-tags">
    ${ Object.keys(TS.STANZA_PUBLIC_PARAMS).map(key => stanza[TS.STANZA_PUBLIC_PARAMS[key]] ? `<li>${stanza[TS.STANZA_PUBLIC_PARAMS[key]]}</li>` : '').join('') }
  </ul>
  `;
}

export function makeIframeHtml(stanza) {
  stanzaCounter++;
  var name = STANZA_ID_PREFIX + stanzaCounter,
      html = `
        <iframe
          class="unload"
          ${stanza['stanza:type'] === 'NanoStanza' ? 'style="width: 160px; height: 160px;"' : ''}
          id="${name}"
          name="${name}"
          src="${stanza['stanza:host']}${trimStanzaId(stanza['@id'])}?${
            // パラメータ
            stanza['stanza:parameter'].map(param => `${param['stanza:key']}=${param['stanza:example']}`).join('&')
          }"
        ></iframe>
        <div class="loading"></div>
      `;
  return html;
}

export function makeJSStanza(stanza) {
  stanzaCounter++;
  const
    id = trimStanzaId(stanza['@id']),
    html = `
      <togostanza-${id} 
        ${
          // パラメータ
          stanza['stanza:parameter'].map(param => `${param['stanza:key']}="${param['stanza:example']}"`).join(' ')
        }
      ></togostanza-${id}>   
    `;
  return html;
}

export function trimPrefix(string) {
  return string.substring(string.indexOf(':') + 1);
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.substring(1);
}

export function trimPrefixAndCaptalize(string) {
  return capitalize(trimPrefix(string));
}

