/* global $ */
import {HOST, STANZA_ID_PREFIX, TS} from './_global.js';
import {setTopMainVisual} from './_topMainVisual.js';
import {setShowcase} from './_showcase.js';
import {setMetastanza} from './_metastanza.js';
import {setStanza} from './_stanza.js';


$(function(){

	switch($('html').data('pageType')) {

		// index
		case 'index':
			getStanzasList()
				.then(json => setTopMainVisual(json));
		break;

		// showcase
		case 'showcase':
			getStanzasList()
				.then(json => setShowcase(json));
		break;

		// showcase
		case 'metastanza':
			getStanzasList()
				.then(json => setMetastanza(json));
		break;

		// stanza
		case 'stanza': {
			getStanzasList()
				.then(json => setStanza(json));
		}
		break;
	}

	// language
	var switchLanguage = function(lang) {
		// set cookie
		$.cookie('LANG', lang, { expires: 365 });
		// switch menu
		$('.lang-switch a').each(function(){
			var $this = $(this);
			if ($this.data('lang') === lang) {
				$this.addClass('current');
			} else {
				$this.removeClass('current');
			}
		});
		// switch document
		$('body [lang]').each(function(){
			var $this = $(this);
			if ($this.attr('lang') === lang) {
				$this.css('display', 'inherit');
			} else {
				$this.css('display', 'none');
			}
		});
	};
	if ($('.lang-switch').length > 0) {
		// get language setting
		var lang = $.cookie('LANG');
		if (lang === undefined) {
			lang = 'en';
		}
		switchLanguage(lang);
		// attach event
		$('.lang-switch a').on('click', function(){
			switchLanguage($(this).data('lang'));
		});
	}






});


/* p5 */
var P5_NUMBER_OF_CIRCLES = 6,
		P5_CANVAS_HEIGHT = 320,
		P5_CANVAS_WIDTH = 996,
		P5_CIRCLE_TOP = 152,
		P5_CIRCLE_MIN_SIZE = 20,
		P5_CIRCLE_MAX_SIZE = 130,
		P5_CIRCLE_MIN_SPEED = 100,
		P5_CIRCLE_MAX_SPEED = 1200,
		P5_CIRCLE_MAX_SPEED = 500,
		P5_METABALL_HANNI = 200,
		P5_RESOLUTION = 6,
		p5Canvas, p5Circles = [], p5Colors = [], p5TrigonometoricFunctions = [];

var P5StanzaCircle = function() {

	var _x, _size, _speed, _id;
	_id = P5StanzaCircle.p5id++;
	//console.log( _id );

	var _init = function(isFirst) {
		var direction = Math.random() > 0.5 ? 1 : -1;
		_size = P5_CIRCLE_MIN_SIZE + (P5_CIRCLE_MAX_SIZE - P5_CIRCLE_MIN_SIZE) * Math.random() * 0.5;
		_speed = (P5_CIRCLE_MIN_SPEED + (P5_CIRCLE_MAX_SPEED - P5_CIRCLE_MIN_SPEED) * Math.random()) * 0.001 * direction;
		if (isFirst) {
			_x = P5_CANVAS_WIDTH * Math.random();
		} else {
			_x = direction === 1 ? 0 - _size : P5_CANVAS_WIDTH + _size;
		}
	};
	_init(true);

	this.update = function() {
		var neighborhoods, drags;
		//console.log(_x, _size);
		
		// くっつき
		neighborhoods = { left: [], right: [] };
		drags = { left: 0, right: 0 };
		for (var i = 0; i < p5Circles.length; i++) {
			//console.log(p5Circles[i].xAndSize());
			var xAndSize = p5Circles[i].xAndSize();
			switch(true) {
				case xAndSize.x < _x: { // 左
					if ((xAndSize.x - _x) > -P5_METABALL_HANNI)	{
						neighborhoods.left.push( { x: xAndSize.x - _x, size: xAndSize.size } );
					}
				}
				break;
				case _x < xAndSize.x: { // 右
					if ((xAndSize.x - _x) < P5_METABALL_HANNI)	{
						//console.log( xAndSize.x - _x );
					}
				}
				break;
			}
			/*
			if (Math.abs(xAndSize.x - _x) < P5_METABALL_HANNI) {
				//console.log(Math.abs(xAndSize.x - _x));
			}
			*/
		}
		// 左
		for (var i = 0; i < neighborhoods.left.length; i++) {
			var x = neighborhoods.left[i].x - _x;
			x *= (Math.abs(x) - P5_METABALL_HANNI) / P5_METABALL_HANNI;
			drags.left += x;
		}
		if (_id === 5) {
			//console.log( neighborhoods );
		}

		// 描画
		//fill(p5Colors[0]);
		if (_id === 5) {
			fill( color(255, 0, 0, 64) );
		} else {
			fill(p5Colors[0]);
		}
		var __x = [];
		beginShape();
		for (var i = 0; i < P5_RESOLUTION; i++) {
			//__x.push(p5TrigonometoricFunctions[i].cos * _size);

			var x, x2, y;
			x = p5TrigonometoricFunctions[i].sin * _size;
			if (_id === 5) {
				if (p5TrigonometoricFunctions[i].sin < 0) {
					x += drags.left;
				} else if (0 < p5TrigonometoricFunctions[i].cos) {

				}
			}
			y = p5TrigonometoricFunctions[i].cos * _size;
			vertex(_x + x, P5_CIRCLE_TOP + y);
		}
		endShape(CLOSE);
		if (_id === 5) {
			//console.log( __x );
		}

		// キャンバスから抜けたか
		if ((_x +_size * 2) < 0 || P5_CANVAS_WIDTH < (_x - _size * 2)) {
			_init(false);
		}
		_x += _speed;
	};

	this.xAndSize = function() {
		return {x: _x, size: _size};
	};

	return this;
}
P5StanzaCircle.p5id = 0;

function setup() {
	p5Canvas = createCanvas(P5_CANVAS_WIDTH, P5_CANVAS_HEIGHT);
	p5Canvas.parent('stage-bg');
	//background(200);
	p5Colors.push(color(243, 152, 0));
	p5Colors.push(color(229, 228, 225));
	noStroke();
	frameRate(15);

	for (var i = 0; i < P5_NUMBER_OF_CIRCLES; i++) {
		p5Circles.push(new P5StanzaCircle());
	}
	for (var i = 0; i < P5_RESOLUTION; i++) {
		var radian = 2 * Math.PI * (i / P5_RESOLUTION) + Math.PI * 0.5;
		p5TrigonometoricFunctions.push({
			sin: Math.sin(radian),
			cos: Math.cos(radian)
		});
	}
	console.log(p5TrigonometoricFunctions);
}

function draw() {
	background(255);
	for (var i in p5Circles) {
		p5Circles[i].update();
	}
}

// Stanza のリストを取得
function getStanzasList() {
	return new Promise((resolve, reject) => {
		$.ajax({
			url: HOST + 'togostanzas.json',
			dataType: 'json'
		}).done(json => {
			resolve(json);
		}).fail(() => {
			reject();
		});
	});
}
