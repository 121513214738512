/* global $ */
import {TS, stanzaCounter, trimStanzaId, trimPrefixAndCaptalize, makeTags, makeIframeHtml, makeJSStanza} from './_global.js';

const jsStanzas = {};
let stanzas;

export function setShowcase(data) {

  // variables
  var STANZA_CONTAINER = 'stanza_container',
      stanzaContainers = [],
      $selectSortType = $('select[name="sort-type"]'),
      $selectSortOrder = $('select[name="sort-order"]'),
      html, stanzaHtml, counter, filterProperties, i, j, key;
  stanzas = data['stanza:stanzas'];

  // stanza (iframe ver.) callback
  //window.onmessage = function(e) {
  //};

  makeLinkImports(stanzas);

  // make stanzas
  html = '';

  filterProperties = (function(){
    var obj = {};
    for (var i in TS.STANZA_PUBLIC_PARAMS) {
      obj[TS.STANZA_PUBLIC_PARAMS[i]] = [];
    }
    return obj;
  })();
  for (i = 0; i < stanzas.length; i++) {
    var stanza = stanzas[i];
    // set properties
    for (j in TS.STANZA_PUBLIC_PARAMS) {
      key = TS.STANZA_PUBLIC_PARAMS[j];
      if ($.inArray(stanza[key], filterProperties[key]) === -1 && stanza[key]) {
        filterProperties[key].push(stanza[key]);
      }
    }
    // make html
    switch (stanza['stanza:implemented']) {
      case 'ruby': stanzaHtml = makeIframeHtml(stanza); break;
      case 'javascript':
        stanzaHtml = makeJSStanza(stanza);
        jsStanzas[stanza['@id']] = {
          data: stanza,
          html: stanzaHtml
        };
        stanzaHtml = 'loading...'
        break;
    }
    html +=
    `<div
      class="list_item"
      id="${STANZA_CONTAINER + i}"
      data-index="${i}"
      data-label="${stanza['stanza:label']}"
      ${ Object.keys(TS.STANZA_PUBLIC_PARAMS).map(key => `data-${TS.STANZA_PUBLIC_PARAMS[key].substring('stanza:' + 1)}="${stanza[TS.STANZA_PUBLIC_PARAMS[key]]}"`).join(' ') }>
      <h2 class="list_ttl">
        <a href="stanza.html?id=${trimStanzaId(stanza['@id'])}">${stanza['stanza:label']}</a>
      </h2>
      ${makeTags(stanza)}
      <div class="showcase-display">${stanzaHtml}</div>
      ` +
    '</div><!-- .list_item -->';
  }
  $('.showcase-list').append(html);
  $('iframe')
    .on('load', function(){
      $(this).removeClass('unload');
    });

  // container
  for (i = 0; i < stanzas.length; i++) {
    var stanza = stanzas[i];
    var $stanzaContainer = $('#' + STANZA_CONTAINER + i);
    stanzaContainers.push($stanzaContainer);
    $stanzaContainer.data('parameter', stanzas[i]);
    if (stanza['stanza:implemented'] === 'javascript') {
      jsStanzas[stanza['@id']].$container = $stanzaContainer.find('.showcase-display');
    }
  }
  console.log(jsStanzas)

  // filter
  // filter: close button
  var $filterTitle = $('#FilterDrawer dt'),
      $filterTitleLabel = $('p', $filterTitle);
  $filterTitle.click(function() {
    $filterTitle.next().slideToggle('fast');
    $filterTitleLabel.toggleClass('close');
  });

  // filter: sum of stanzas
  $filterTitleLabel.text('Filter (' + stanzas.length + ' stanzas / ' + stanzas.length + ')');

  // filter: make checkboxes
  html = '';
  for (i in filterProperties) {
    // shift 'other'
    const filterProperty = filterProperties[i];
    const index = filterProperty.indexOf('Other');
    if (index >= 0) {
      filterProperty.splice(index, 1);
      filterProperty.push('Other');
    }
    // html
    key = trimPrefixAndCaptalize(i);
    html += '<tr><th>' + key + '</th><td><input type="checkbox" name="f_' + key + '" value="All" id="f_' + key + 0 + '" data-key="' + i + '"><label for="f_' + key + 0 + '" class="checkbox">All</label>';
    for (j in filterProperty) {
      j = parseInt(j);
      var value = trimPrefixAndCaptalize(filterProperty[j]);
      html += '<input type="checkbox" name="f_' + key + '" value="' + value + '" id="f_' + key + (j + 1) + '" data-value="' + filterProperty[j] + '"><label for="f_' + key + (j + 1) + '" class="checkbox">' + value.replace(key, '') + '</label>';
    }
    html += '</td></tr>';
  }
  $('#FilterDrawer dd tbody').prepend(html);

  // filter: checkboxes
  // TODO: cookieに前回のフィルタ保存されていて、読み込む
  var filters = [];
  $('#FilterDrawer td').each(function(index1, elm1){
    var checkboxes = [];
    $('input[type="checkbox"]', elm1).each(function(index2, elm2){
      var $checkbox = $(elm2);
      //elm2.$ = $checkbox;
      if (index2 === 0) {
        $checkbox.prop('checked', true);
      }
      $checkbox
        .attr('data-index', index2)
        .attr('data-filter', index1)
        .on('change', function() {
          // update checkboxes
          updateCheckbox(this);
        });
      checkboxes.push($checkbox);
    });
    if (checkboxes.length > 0) {
      filters.push(checkboxes);
    }
  });

  // filter: update checkbox
  var updateCheckbox = function(elm) {
    var checkboxes = filters[parseInt(elm.dataset.filter)],
        $checkbox, i, j;
    if (elm.dataset.index === '0') { // 'All'
      for (i = 1; i < checkboxes.length; i++) {
        checkboxes[i].prop('checked', false);
      }
    } else {
      let allChecked = true;
      for (i = 1; i < checkboxes.length; i++) {
        if (! checkboxes[i].prop('checked')) {
          allChecked = false;
        }
      }
      if (allChecked) {
        checkboxes[0].prop('checked', true);
        for (i = 1; i < checkboxes.length; i++) {
          checkboxes[i].prop('checked', false);
        }
      } else {
        checkboxes[0].prop('checked', false);
      }
    }
    // status
    const status = {}, statusIsAll = {};
    for (i = 0; i < filters.length; i++) {
      checkboxes = filters[i];
      const key = checkboxes[0].data('key'),
            isAll = checkboxes[0].prop('checked');
      statusIsAll[key] = isAll;
      status[key] = [];
      for (j = 1; j < checkboxes.length; j++) {
        $checkbox = checkboxes[j];
        if (isAll || $checkbox.prop('checked')) {
          status[key].push($checkbox.data('value'));
        }
      }
    }
    // filtering
    counter = 0;

    stanzaContainers.forEach(($stanza, i) => {
      const parameters = $stanza.data('parameter');
      let isVisible = true;
      for (const filterKey in status) {
        const filtered = status[filterKey];
        if (filtered.indexOf(parameters[filterKey]) === -1) {
          isVisible = false;
          break;
        }
      }
      if (isVisible) {
        $stanza.removeClass('hidden');
      } else {
        $stanza.addClass('hidden');
      }

    });

    // sum of stanzas
    $filterTitleLabel.text('Filter (' + counter + ' stanzas / ' + stanzas.length + ')');
  };

  // sort
  $('.sort select').on('change', () => sortStanza());
  var sortStanza = function() {
    var sortKeywords = [],
        keyword = $selectSortType.val().toLowerCase(),
        sortedStanzaContainers = [],
        i;
    // get keywords
    keyword = keyword === 'default' ? 'index' : keyword;
    keyword = keyword === 'alphabetical' ? 'label' : keyword;
    for (i in stanzaContainers) {
      var value = stanzaContainers[i].data(keyword);
      if ($.inArray(value, sortKeywords) === -1) {
        sortKeywords.push(value);
      }
    }
    // sort
    if (keyword !== 'index') {
      sortKeywords.sort();
    }
    // search matching obj
    for (i in sortKeywords) {
      const $filteredStanzaContainers = $('.list_item[data-' + keyword + '="' + sortKeywords[i] + '"]');
      sortedStanzaContainers = $.merge(sortedStanzaContainers, $filteredStanzaContainers);
    }
    // layout
    var top = 0,
        order = $selectSortOrder.val() === 'Ascend';
    for (i = 0; i < sortedStanzaContainers.length; i++) {
      var index = order ? i : sortedStanzaContainers.length - i - 1;
      var $stanzaContainer = $(sortedStanzaContainers[index]);
      $stanzaContainer.css({
        position: 'absolute',
        top: top
      });
      top += $stanzaContainer.outerHeight();
    }
    $('.showcase-list').css('height', top);
  };
}

function makeLinkImports(stanzas) {
  const head = document.querySelector('head');
  stanzas.forEach(stanza => {
    if (stanza['stanza:implemented'] === 'javascript') {
      const link = document.createElement('link');
      link.rel = 'import';
      link.href = stanza['stanza:host'] + stanza['@id'];
      link.setAttribute('stanzaId', stanza['@id']);
      link.addEventListener('load', () => {
        const stanza = jsStanzas[link.getAttribute('stanzaId')];
        stanza.$container.html(stanza.html)
      })
      head.appendChild(link);
    }
  });
}